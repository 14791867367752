import { Avatar, Button } from 'antd'
import React, { useState } from 'react'
import {CopyOutlined, UserOutlined, PhoneOutlined} from '@ant-design/icons'
import { returnSamvaadiniFilteredLeadsBgColor } from '../../../constants/utils'
import CopyIcon from '../../../static/svg/CopyIcon'
import CallIcon from '../../../static/svg/CallIcon'
import moment from 'moment'
import CopyTextCheckMark from '../../../static/svg/CopyTextCheckMark'

function InterestedCandidateCard({indexVal, candidate}) {
    const [copyOperationSuccessful, setCopyOperationSuccessful] = useState(false);
    const noReferralTxt = "no_referral"
    const getTime = (createdDate) => {
        console.log("createdDate: ", createdDate);
        if (!createdDate) return '';
        // Convert createdDate to local time (IST)
        const localDate = moment(createdDate).subtract(330, 'minutes').local();
        const diffInDays = moment().diff(localDate, 'days');
        const diffInHours = moment().diff(localDate, 'hours');
        const diffInMinutes = moment().diff(localDate, 'minutes');
    
        if (diffInDays > 0) {
            return `${diffInDays}d ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours}hrs ago`;
        } else {
            return `${diffInMinutes}mins ago`;
        }
    };
    
    

    const copyPhoneNumber = async() => {
        try {
            await navigator.clipboard.writeText(candidate?.phoneNumber);
            setCopyOperationSuccessful(true);
            
            setTimeout(() => setCopyOperationSuccessful(false), 1000);
          } catch (err) {
            console.log(err);
          }
    }
  return (
    <div className="filtered-individual-candidate hoverable-card" style={{background: returnSamvaadiniFilteredLeadsBgColor(indexVal)}}>
        <div className="client-info-section">
            {
                candidate?.type == noReferralTxt ? <img src="/image/no_referred_diamond.png" style={{height:'28px', width:'28px'}}/> : <img src={candidate?.latestClientLogoUrl} style={{width:'56px'}}/>
            }
        </div>

        <div className="filtered-lead-info-container">
            <div className="filtered-lead-info d-flex align-items-center">
                <img src='/image/candidate-samvaadini.png' style={{height: '36px',width:'36px'}}/>
                <div className="filtered-candidate d-flex flex-column">
                    <span className='filtered-lead-name'>{candidate?.name}</span>
                    <span className='filtered-lead-number'>{candidate?.phoneNumber}</span>
                </div>
            </div>
            <div className="action-ctas">
                <div style={{cursor: 'pointer', marginTop: copyOperationSuccessful ? '-2px':''}} onClick={copyPhoneNumber}>
                    {
                        copyOperationSuccessful ? <CopyTextCheckMark height={20} width={20}/>:<CopyIcon height={24} width={24}/>
                    }
                </div>
                <a href={`tel:+91-${candidate?.phoneNumber}`}>
                    <div style={{cursor: 'pointer'}}><CallIcon height={24} width={24}/></div>
                </a>
            </div>
        </div>

        <div className="filtered-lead-other-details d-flex align-items-center">
            <div className="field-container align-items-start">
                <span className='filtered-lead-header'>{candidate?.type == noReferralTxt ? "Upload Date" : "Referral Date"}</span>
                <span className='filtered-lead-sub-header'>{candidate?.type == noReferralTxt ? moment(candidate?.uploadDate).format('DD/MM/YYYY') : candidate?.latestClientReferredDate ? moment(candidate?.latestClientReferredDate).format('DD/MM/YYYY'): null}</span>
            </div>

            {
                candidate?.type == noReferralTxt ? null :
                <div className="field-container align-items-start">
                    <span className='filtered-lead-header'>City</span>
                    <span className='filtered-lead-sub-header'>{candidate?.latestReferredLocation}</span>
                </div>
            }
            <div className="field-container align-items-start">
                <span className='filtered-lead-header'>Owner</span>
                <span className='filtered-lead-sub-header'>{candidate?.referredBy}</span>
            </div>

            {
                candidate?.type == noReferralTxt ? null :
                <div className="field-container align-items-start">
                    <span className='filtered-lead-header'>Call time</span>
                    <span className='filtered-lead-sub-header'>{getTime(candidate?.calledAt)}</span>
                </div>
            }
        </div>
    </div>
  )
}

export default InterestedCandidateCard