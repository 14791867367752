import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../App';
import { getFlipkartAttendanceData } from './../../service/reportService';
import { Card, Typography, Skeleton, DatePicker } from 'antd';
import { RightCircleOutlined, ProjectFilled } from '@ant-design/icons';
import SectionLayout from '../common/SectionLayout.component';
import { getActiveClientsForFilter, getMitraTeamMembersList, getLocationForFilter } from './../../service/commonService'
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const { Text } = Typography;

const FlipkartAttendanceComponent = () => {
    const { mitraReducer, mitraDispatch } = useContext( AppContext );
    const [ displayFlipkartAttendanceSection, setDisplayFlipkartAttendanceSection ] = useState( false );
    const defaultDate = moment().date() < 10 ? moment().subtract(1, 'month').endOf('month') : moment();
    const [ selectedDate, setSelectedDate ] = useState( defaultDate );
    const [ selectedMonth , setSelectedMonth ] = useState( defaultDate.month() + 1 );
    const [ year , setYear ] = useState( defaultDate.year() );
    const [ attendanceData, setAttendanceData ] = useState(null);
    const [ logo, setLogo ] = useState(null);
    const [ loading, setLoading ] = useState( true );
    const [ clientList, setClientList ] = useState();
    const [ teamList, setTeamList ] = useState();
    const [ locationFilter, setLocationFilter ] = useState();

    const getFlipkartAttendanceDataHandler = async ( payload ) => {
        try {
            const res = await getFlipkartAttendanceData( payload ); //
            console.log( "Response Data:", res );
            if( res.data.status ) {
                setAttendanceData( res.data.data.attendance );
                setLogo( res.data.data.logo );
                setDisplayFlipkartAttendanceSection( true );
                setLoading( false );
            }
        } catch ( error ) {
            console.log( "Error fetching attendance data: ", error );
        }   
    }

    useEffect(() => { 
        const data = {
            mitraId: mitraReducer?.mitraInfo?.id,
            month: selectedMonth,
            year: year,
            isMonthWise: true
        }
        if (mitraReducer?.mitraInfo?.id && selectedMonth) {
            setLoading( true );
            getFlipkartAttendanceDataHandler(data);
        }
    }, [ mitraReducer?.mitraInfo?.id, selectedMonth, year ]);

    const history = useHistory();
    const routing = ( attendanceRange ) => {
        const startDate = moment(`${year}-${selectedMonth}-01`).startOf('month').format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
        const title = "Flipkart Attendance";
        handleSubmit( startDate, endDate, title, attendanceRange );
    }

    const handleDateRange = (dates) => {
        sessionStorage.setItem('flipkartSelectedDate', moment(dates).format('MM-YYYY'));
        setSelectedDate(dates);
        setSelectedMonth(moment(dates).format('MM'));
        setYear(moment(dates).format('YYYY'));
    }

    const handleSubmit = async ( startDate, endDate, title, attendanceRange ) => {
        const teamResponse = await getMitraTeamMembersList();
        const teamFilterList = teamResponse?.data?.data?.map(user => ({
            label: user.name,
            id: user.id,
            value: false
        }));
        setTeamList(teamFilterList);
    
        const clientResponse = await getActiveClientsForFilter();
        const clientFilterList = clientResponse?.data?.activeClients?.map(client => {
            if (client.label === 'Flipkart') {
                return { ...client, value: true };
            }
            return client;
        });
        setClientList(clientFilterList);

        const locationResponse = await getLocationForFilter();
        const locationFilterList = locationResponse?.data?.locationList;
        setLocationFilter(locationFilterList);
    
        mitraDispatch({
            type: 'reportParams',
            value: {
                clientList: clientList,
                typeOfLead: 'All Leads',
                teamList: teamList,
                locationFilter: locationFilter
            }
        });

        history.push({
            pathname: '/leads-page',
            search: `${title}&startDate=${startDate}&endDate=${endDate}&attendanceRange=${attendanceRange}`
        });
    };

    useEffect(() => {
        getMitraTeamMembersList().then(response => {
            let list = []
            for (let user in response?.data?.data) {
                list.push({ label: response?.data?.data[user]?.name, id: response?.data?.data[user]?.id, value: false });
            }
            setTeamList(list);
            getActiveClientsForFilter().then(data => {
                let activeClientsResponseList = data.data.activeClients;
                activeClientsResponseList = activeClientsResponseList.map(client => {
                    if (client.label === 'Flipkart') {
                        return { ...client, value: true };
                    }
                    return client; 
                });
                setClientList(activeClientsResponseList);
                getLocationForFilter().then(data => {
                    let locationFilterList = data?.data?.locationList;
                    setLocationFilter(locationFilterList);
                });
            });
        });
    }, [ mitraReducer?.mitraInfo?.id ]);
    return (
        <>
          {       
                <SectionLayout 
                    themeWhite = { false } 
                    icon       = { <ProjectFilled style={{display: 'flex'}}/>}
                    title      = {
                            <span style={{fontWeight: 400}}> FLIPKART ATTENDANCE </span> 
                    }
                    datepicker   = {
                        <DatePicker
                            onChange={handleDateRange}
                            format="MMMM YYYY"
                            allowClear={false}
                            picker="month"
                            value={selectedDate}
                            defaultValue={selectedDate}
                            style={{ width: 150 }}
                        />
                    }
                >
                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            ( displayFlipkartAttendanceSection && !loading ) ?   
                                <Card 
                                    bodyStyle={{padding: '12px 24px'}} 
                                    className='overall-card overall-card-clientperformance total-referral-box' 
                                    style={{ 
                                        margin: '0 auto',
                                        width: '100%',
                                    }} 
                                >
                                    <div className='card-header-section d-flex justify-content-between' style={{border: 0}}>
                                        <img className="imgResponse" alt='flipkart' src={logo} />
                                    </div>
                                    <div className='overall-summary-box' >
                                            <div className='flipkart-flex-wrap'>
                                                {attendanceData && attendanceData.map((data, index) => (
                                                    <div key={index} className='pre-ob-box-container' style={{ borderRight: data.label === 'Action Needed Referrals' ? '1px solid #f0f0f0' : '' }}>
                                                        <div className='total-referral-container'>
                                                            <div className='d-flex align-items-center pre-ob-text'>
                                                                <Text style={{ margin: '5px', padding: '2px', fontSize: '12px', fontWeight: 'bold' }}>{data.label}</Text>
                                                            </div>
                                                            <div className='d-flex align-items-center' style={{ marginTop: '10px' }} onClick={(e) => routing( data.attendance_range )}>
                                                                <Text className='total-referral-number' style={{ fontSize: '20px' }}>{data.user_count}</Text>
                                                                <RightCircleOutlined className='right-circle-icon' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                    </div>
                                </Card>
                            : <Skeleton active/>
                        }
                    </div>
                </SectionLayout> 
          }  
        </>
    );
}

export default FlipkartAttendanceComponent;