import React, {useState, useEffect, useContext ,useReducer} from 'react';
import Lead from '../common/candidateApplication/CandidateApplicationCard.component';
// import CandidateProfilePopupV2 from './candidateProfilePopupV2Components'
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import moment from "moment";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { DatePicker, Button ,Tag , Space ,Typography, Skeleton, Pagination, Empty, Tabs,Modal,Result, Dropdown, Menu, notification, Row, Col } from 'antd';
import {AppContext} from '../../App';
import {getActiveClientsForFilter, disabledDate, getStatusListForFilters, getRapidoDocumentStatuses, getLocationForFilter, getMitraTeamMembersList} from './../../service/commonService'
import {SearchOutlined, FilterOutlined, WhatsAppOutlined, InfoCircleOutlined, DownOutlined, ProfileOutlined} from '@ant-design/icons';
import {getCandidatesData, downloadCandidatesPageData, displayexpiryDateChangedBanner,getHotLeadData} from './../../service/leadService'
import SideSheet from "../common/SideSheet"
import SideInput from "../common/SiderInput"
import axios from 'axios';
// import PageHeaderComponent from '../common/PageHeader';
import '../../css/lead.scss';
import { useLocation } from "react-router-dom";
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import { getViewDetailsData } from './../../service/leadService';
import CandidateProfilePopupV2 from './candidateProfilePopupV2Components/candidateProfilePopupV2';
import {useParams} from "react-router-dom";
import * as queryString from 'query-string';
import RaiseIssueSider from '../help/RaiseIssueSider.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import { rollOutRecruitmentMilestoneVisibility, jobseekerApplicationInML } from '../../constants/utils';
import {getDisplayableClientName} from './../../utils/commonFunction';
import axiosInstance from '../../service/interceptorService';


const { Text } = Typography;
const { RangePicker } = DatePicker;
const louTours = {
	'Candidate page tracking':815030298538
}
const ZomatoHotLeadsPage = () => {
	const location = useLocation();
	const stateData = JSON.parse(location.state?location.state:'{}');
	const history=useHistory();
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	// const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	// const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
    const {mitraReducer, setAlert,setSpin } = useContext(AppContext);
	const [leads, setLeads] = useState([]);
	const [startDate, setStartDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [filterSider, setfilterSider] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)
	// const [breadcrumb, setBreadcrumb] = useState([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/all-candidates', label: 'All Candidates'}])
	const [clientSelected, setClientSelcted] = useState([])
	const [referAgain, setReferAgain] = useState(false);
	const [currentJob, setCurrentJob] = useState({});
    const [submitdisable, setSubmitdisable] = useState(true);
    const [alternateNumber, setAlternateNumber] = useState();
	const [statusesSelected, setStatusesSelected] = useState([]);
	const dateFormat = 'YYYY/MM/DD';
	const [filterApplied, setFilterApplied] = useState(true)
    const [mitraList, setMitraList] = useState([])
	const [totalApplicationCount, setTotalApplicationCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(50)
	const [downloadLoading, setDownloadLoading] = useState(false)
	const [currentTab, setCurrentab] = useState('priority1')
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [candidatePostReferralSteps,setCandidatePostReferralSteps] = useState([]);
	const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
	const [leadDetails, setLeadDetails] = useState([]);
	const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
	const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
	const trackMoengageEvent = (event,obj) => {
		trackEvent(event, obj || {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
	const [showBanner, setShowBanner] = useState(false)
	const [trackUpdatedExpiryDate, setTrackUpdatedExpiryDate] = useState(false)
	const {tab} = useParams();
	const [raiseIssueSider, setRaiseIssueSider] = useState(false);
	const [viewDetailLead, setViewDetailLead] = useState({});
	const [locationList, setLocationList] = useState([])
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
	const [mitraAppDownloadedFilter, setMitraAppDownloadedFilter] = useState([{label: 'Downloaded', value: false, key: 'downloaded'},{label: 'Not Downloaded', value: false, key: 'not_downloaded'}]);
	const [documentStatusFilter, setDocumentStatusFilter] = useState([{label: 'Complete', value: false, key: 'complete'},{label: 'Incomplete', value: false, key: 'incomplete'}]);
	const [applicationType, setApplicationType] = useState([{label: 'Mitra App Applications', value: false, key: 'ma_applications'},{label: 'Mitra Leader Applications', value: false, key: 'ml_applications'}])

	const handleScroll = () => {
		let leadsWrapperDiv  = document.getElementsByClassName('leads-wrapper')[0];
		let backToTopBtn = document.getElementById("back-to-top");
		let y = leadsWrapperDiv.scrollTop;
		if (y >= 1500) {
			backToTopBtn.className = "cta showCta"
		} else {
			backToTopBtn.className = "cta hideCta"
		}
	};
	useEffect(() => {
        let zomatoRequiredMilestoneKeys = ["p3AppDownloadedButNoSectionFilled", "p2Only1or2SectionsFilled", "p1Upto3or4SectionsFilled", "p0Upto5SectionsFilled", "obFeesPaid"]
        console.log("tab:>> in useEffect:>>", tab)
		if(mitraReducer?.mitraInfo){
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})

			// if (jobseekerApplicationInML(mitraReducer?.mitraInfo)) {
			// 	setApplicationType([{ label: 'Mitra App Applications', value: true, key: 'ma_applications' }, { label: 'Mitra Leader Applications', value: false, key: 'ml_applications' },{label: 'Claimed Applications', value: false, key: 'claimed_applications'}])
			// }

			getActiveClientsForFilter().then(data => {
				setClientSelcted(data.data.activeClients)
			})  
			getStatusListForFilters().then(data => {
                let filteredStatuses = data.data.statuses.filter(status => {
                    return zomatoRequiredMilestoneKeys.includes(status.key);
                  });
                console.log("called getStatusList:>>", filteredStatuses)
				setStatusesSelected(filteredStatuses)
                // setStatusesSelected(data.data.statuses)
			})
			getLocationForFilter().then(response=>{
				setLocationList(response.data.locationList)
			})
			
			if(tab === 'priority1') 
			{
				// setCurrentab('action-needed')
				// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/action-needed', label: 'Action Needed'}])
			}
        }
		setPageLimit(50)
	}, [mitraReducer])

	useEffect(() => {
        console.log("called useEffect [mitra, filterApplied]");
		let element  = document.getElementsByClassName('leads-wrapper')[0];
		element.addEventListener('scroll', handleScroll, { passive: true });
		if(mitra?.id && filterApplied) {
			setLoaded(false)
			let clientList = [], statusList = [], selectLocationList=[], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType;
            
            clientList.push('zomato')
            // statusList = ["leadReferred","p3AppDownloadedButNoSectionFilled","p2Only1or2SectionsFilled","p1Upto3or4SectionsFilled","p0Upto5SectionsFilled"];
			
            clientSelected.map(client => {
				if(client.value) {
					clientList.push(client.label.toLowerCase())
				}
			})

			statusesSelected.map(status => {
				if(status.value) {
					statusList.push(status.key)
				}
			})

			locationList.map(status => {
				if (status.value) {
					selectLocationList.push(status.label)
				}
			})	

			selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

			selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;

			selectedApplicationType = applicationType.find(item => item.value)?.key;

			if(selectLocationList.length > 0){
				trackEvent('clicked_city_filter_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			}
			const searchParams = new URLSearchParams(window.location.search);

			const isOlap = searchParams.get("isOlap");
			console.log('isOlap 2:>> ', isOlap);
			if(!isOlap){
				let data = {
					startDate: startDate,
					endDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
					clients: JSON.stringify(clientList),
					statuses: JSON.stringify(statusList),
					offset: currentPage*pageLimit - pageLimit,
					limit: pageLimit,
					targetClient: 'zomato',
					// incompleteApplications: currentTab === 'all-candidates' ? false : true,
					updatedExpiryDateLeads: trackUpdatedExpiryDate,
					locations: selectLocationList.length > 0 ? selectLocationList : [],
					// mitraAppDownloadedFilter: selectedMitraAppDownloadedFilter,
					isEligibleForRecruitmentFlow: rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo),
					documentStatusFilter: selectedDocumentStatusFilter,
					applicationType: selectedApplicationType,
					zomatoHotLeadsTab: 'combined'
				}
				console.log("currentTab before calling get Candidates Data API:>>", currentTab);
				const t0 = performance.now();
				getMitraTeamMembersList().then(response => {
					setMitraList(response.data.data);
				});
				getHotLeadData(data).then((result) => {
					if(location.state){
						openNextStepsPopUp()
					}
					setLoaded(true)
					setLeads(result.data.result);
					// if(result.data.count && Number(result.data.count) > 0){
						setTotalApplicationCount(Number(result.data.count))
					// }
					setFilterApplied(false)
					const t1 = performance.now();
					console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
					trackEvent('candidates_page_load_time', {
						candidates_page_load_time:(t1 - t0)
					},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				})
				if(currentTab === 'all-candidates' && !trackUpdatedExpiryDate) {
					displayexpiryDateChangedBanner({startDate: startDate, endDate: endDate}).then(response => {
						setShowBanner(response.data.showBanner)
					})
				}
			}else{
				let data = {
					startDate: startDate,
					endDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
					clients: JSON.stringify(clientList),
					statuses: JSON.stringify(statusList),
					offset: currentPage*pageLimit - pageLimit,
					limit: pageLimit,
					targetClient: 'zomato',
					// incompleteApplications: currentTab === 'all-candidates' ? false : true,
					updatedExpiryDateLeads: trackUpdatedExpiryDate,
					locations: selectLocationList.length > 0 ? selectLocationList : [],
					// mitraAppDownloadedFilter: selectedMitraAppDownloadedFilter,
					isEligibleForRecruitmentFlow: rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo),
					documentStatusFilter: selectedDocumentStatusFilter,
					applicationType: selectedApplicationType,
					zomatoHotLeadsTab: 'combined'
				}
				console.log("currentTab before calling get Candidates Data API:>>", currentTab);
				const t0 = performance.now();
				getMitraTeamMembersList().then(response => {
					setMitraList(response.data.data);
				});
				getCandidatesData(data).then((result) => {
					if(location.state){
						openNextStepsPopUp()
					}
					setLoaded(true)
					setLeads(result.data.result);
					// if(result.data.count && Number(result.data.count) > 0){
						setTotalApplicationCount(Number(result.data.count))
					// }
					setFilterApplied(false)
					const t1 = performance.now();
					console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
					trackEvent('candidates_page_load_time', {
						candidates_page_load_time:(t1 - t0)
					},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				})
				if(currentTab === 'all-candidates' && !trackUpdatedExpiryDate) {
					displayexpiryDateChangedBanner({startDate: startDate, endDate: endDate}).then(response => {
						setShowBanner(response.data.showBanner)
					})
				}
			}
			
		}
	}, [mitra, filterApplied])

	useEffect(()=> {
		const params = queryString.parse(window.location.search);
		if (params?.startDate && params?.endDate) {
			handleDateRange([moment(params.startDate), moment(params.endDate)]);
		}
	}, []);

	const openNextStepsPopUp =()=>{
		setTimeout(() => {
			let serialNo=0;
			const steps = CONSTANTS.CANDIDATE_POST_REFERRAL_STEPS.map(ele=>{
				if(ele?.clients?.includes(stateData?.clientPreference?.toLowerCase())){
					serialNo++;
					if(!ele.hasClickableUrl) {
						return <h5 style={{textAlign:'left'}} key={'point'+serialNo}> {serialNo} {ele.message}</h5>;
					} else {
						// eslint-disable-next-line
						var urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
						let urls = ele.message.match(urlRegex)
						let msgBreakup = [];
						let msg = ele.message;
						urls.forEach((url) => {
							let index = msg.indexOf(url)
							msgBreakup.push(msg.slice(0,index));
							msgBreakup.push(msg.slice(index, index+url.length));
							msg = msg.slice(index+url.length, msg.length);
						})
						msgBreakup.push(msg);
						let main = <span>
							{
								msgBreakup.map((element, index) => {
									if(urls.includes(element)) {
										return <a key={index} href={element} target = '_blank' rel="noreferrer">{element}</a> ;
									} else {
										return element;
									}
								})
							}
						</span>
						return <h5 style={{textAlign:'left'}} key={'point'+serialNo}> {serialNo}. {main} </h5>;
					}
				}
			})
			setCandidatePostReferralSteps(steps)
			setIsModalOpen(true)
		}, 500);
	}
	const deleteChip = (name, item) => {
		if (name === 'status') {
			let newStatus = [...statusesSelected]
			newStatus.map((single_status) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if (name === 'category') {
			let newCategory = [...clientSelected]
			newCategory.map((single_category) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if(name === 'updateExpiryFilter'){
			setTrackUpdatedExpiryDate(false)
		}
		else if (name === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		}
		else if (name === 'mitraAppDownload') {
			let newCategory = [...mitraAppDownloadedFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setMitraAppDownloadedFilter(newCategory)
		}
		else if(name === 'documentStatus') {
			let newCategory = [...documentStatusFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setDocumentStatusFilter(newCategory)
		}
		else if (name === 'applicationType') {
			let newCategory = [...applicationType]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setApplicationType(newCategory)
		}
		setFilterApplied(true)
	}

	const handleDownload = () => {
        trackEvent('zomatoHotLeadsFileDownloadClicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowVerifyEmailPopup(true)
	}
    
	const handleSheet = async (email) => {
		if(leads.length > 0) {
			setDownloadLoading(true)
			let clientList = [], statusList = [], selectedLocationList = [], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType, teamMemberList = [];
			clientSelected.map(client => {
				if(client.value) {
					clientList.push(client.label.toLowerCase())
				}
			})
	
			statusesSelected.map(status => {
				if(status.value) {
					statusList.push(status.key)
				}
			})
            mitraList.map(status => {
                if(status.value) {
                    teamMemberList.push(status.id)
                }
            })
			locationList.map(location => {
				if(location.value) {
					selectedLocationList.push(location.label)
				}
			})

			selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

			selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;
	
			selectedApplicationType = applicationType.find(item => item.value)?.key;

			const data = {
				startDate: startDate,
				endDate: moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
				clients: JSON.stringify(['zomato']),
                targetClient: 'zomato',
				statuses: JSON.stringify(statusList),
				// incompleteApplications: currentTab === 'all-candidates' ? false : true,
				updatedExpiryDateLeads: trackUpdatedExpiryDate,
				locations: selectedLocationList.length > 0 ? selectedLocationList : [],
				// mitraAppDownloaded: selectedMitraAppDownloadedFilter,
				documentStatus: selectedDocumentStatusFilter,
				applicationType: selectedApplicationType,
                teamMemberIds:JSON.stringify(teamMemberList),
				email,
				changeEmail,
                zomatoHotLeadsTab: currentTab
			}
			downloadCandidatesPageData(data).then((response) => {
				if(response.data.responseCandidatesUrl){
					window.open(response.data.responseCandidatesUrl, '_blank')
				}
				else {
					setAlert({
                        display: true,
                        text: response.data.message,
                        type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
                    });
				}
				setDownloadLoading(false)
			})
		}
		else{
			setAlert({
				display: true,
				text: 'No data Present',
				type: 'error'
				});
		}
	}
	
	const whatsAppMessageModalCancel = () => {
		setWhatsAppMessageModal(false);
	};
	const onPhoneNumberChange = async (e) => {
		if(e.target.value.length > 10) {
			setAlert({
                display:true,
                text:'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
                type:'error'
              });
			setSubmitdisable(true);
			return;	
		}
		else {
			const alternatePhone = e.target.value;
			const regex = new RegExp("^[0-9]{10}$");
			if(regex.test(alternatePhone)){
				setAlternateNumber(alternatePhone);
				setSubmitdisable(false);
			} else {
				setSubmitdisable(true);
			}
		
		}
		
    }
	const whatsappModalOpen =()=>{
		const lead = leads.find(lead=>lead.phoneNumber == stateData.phoneNumber)
		setSelectedLead(lead)
		if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
			let data = {
				recommendationHistoryIds: lead.recommendationHistoriesId,
				scheduledInterviewId: lead?.scheduledInterviewId,
			};
			getViewDetailsData(data).then((result) => {
				setLeadDetails(result.data);
				setWhatsAppMessageModal(true)

			});
		}
	}
	const scrollToTop = () => {
		trackEvent('Click_on_go_to_top_candidates_page',{}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const leadsWrapper = document.getElementsByClassName("leads-wrapper");
		leadsWrapper[0].scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}
    const submitLeadFilter=()=>{
		const data = new FormData();

		data.append('userId', selectedLead.id);
		data.append('oldPhoneNumber', currentJob.phoneNumber);
		data.append('userPhoneNumber', currentJob.phoneNumber);
		data.append('phoneNumber', alternateNumber);
		data.append('desiredRole', currentJob.jobType);
		data.append('jobId', currentJob.jobId);
		data.append('sourceName', 'mitra-leader');
		data.append('jobDemandId', currentJob.jobDemandId);
		data.append('sourceId',currentJob.mitraId);
		data.append('name', currentJob.name);
		data.append('clientPreference', currentJob.companyName);
		data.append('cityPreference', currentJob.companyCity);
		data.append('role', currentJob.role);
		data.append('companyCity', currentJob.companyCity);
		data.append('managerMitraID', mitra.managerMitraID ?  mitra.managerMitraID : currentJob.mitraId );
		let url = process.env.REACT_APP_BACKEND_URL + '/referAgain';
	
		let response = axiosInstance.post(url, data, {withCredentials: true});
		setAlert({
			display:true,
			text: 'Trying to refer with other number....'
		});
		response.then(function (response) {
			if (!response.data.status) {
				let message = response.data.message ? response.data.message : 'Sorry, try another number.';
				setAlert({
					display:true,
					text: message,
					type:'error'
				});	
			} else {
				setAlert({
					display:true,
					text:'successfully referred!!',
					type:'success'
				});
			
				setReferAgain(false);
				setFilterApplied(true);
			}
		});
        setSubmitdisable(true);
    }

	const handleDateRange = (dates) => {
		if(dates === null){
			setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else{
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const clearAll=()=>{
		clientSelected.map(item => {
			item.value=false;
		})
		statusesSelected.map(item => {
			item.value=false;
		})
		setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
		setEndDate(moment());
		setFilterApplied(true)
	}

	

	const onClickTags=(label,index)=>{
		if(label === 'Status'){
			statusesSelected[index].value=!statusesSelected[index].value;
		}
		// if(label === 'Clients'){
		// 	clientSelected[index].value=!clientSelected[index].value;
		// }
		// if(label === 'Location'){
		// 	locationList[index].value=!locationList[index].value;
		// }
		// if(label === 'Mitra App (Mitra Clients)' || label === 'Mitra App'){
		// 	mitraAppDownloadedFilter[index].value = !mitraAppDownloadedFilter[index].value;
		// 	mitraAppDownloadedFilter[1 - index].value = false;
		// }
		// if(label === 'Document Status (Mitra Clients)'){
		// 	documentStatusFilter[index].value = !documentStatusFilter[index].value;
		// 	documentStatusFilter[1 - index].value = false;
		// }
		// if(label === 'Application Type'){
		// 	applicationType.map(ele=>ele.value = false);
		// 	applicationType[index].value = !applicationType[index].value;
		// 	// applicationType[1 - index].value = false;

		// }
		forceUpdate();
	}

	const  preventDefault=(e,label,item) =>{
        e.preventDefault()
        deleteChip(label, item)
        console.log('Clicked! But prevent default.');
    }

	const applyFilterCondition = () =>{
        let result=false;
        let arr = [statusesSelected,clientSelected,endDate];
        console.log("statuses selected in applyFilterCondition:>>", statusesSelected);
        arr.map(item=>{
            if(item){
                for (let i = 0; i < item.length; i++) {
                    if (item[i].value === true) {
                        result=true;
                        break;
                    }
                  }
            }
            if(moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
                result = true;
            } 
        })
        return result;
    }
	const submitFilter = () => {
		setFilterApplied(true)
	}

	const commonTag = (label,arrObj) =>{
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<Space>
					<RangePicker
						defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormat}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
						}}
						disabledDate={disabledDate}
						allowClear={false}
						className="range-picker-leads"
					/>
				</Space> : 
				label === "Location" ? 
                <LocationFilterComponent
                    locationList={locationList}
                    onClickTags={onClickTags}
                    label={label}
                    setLocationList={setLocationList}
                />
                :
				<div className='tag-wrapper-container'>
					{arrObj.map((item,index)=>{
						return <Tag className={!item.value ? 'tags':'tags tag-active'}
						key={index}
						// closable={item.value}
						onClick={()=>{onClickTags(label,index)}}
						>
							{label === "Clients" ? getDisplayableClientName(item.label) : item.label}
						</Tag>
					})}
				</div>
			}
			
		</div>
	}

	const onChangeTabs =(index)=>{
        console.log("index of tab:>>", index);
		if(index ==='1'){
			// history.push({pathname: '/leads/all-candidates'})
			setCurrentab('priority1')
            trackEvent('zomatoHotLeadsP1TabClicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/all-candidates', label: 'All Candidates'}])
			setFilterApplied(true)
		}else if (index === '2'){
			// history.push({pathname: '/leads/action-needed'})
			setCurrentab('priority2')
            trackEvent('zomatoHotLeadsP2TabClicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setTrackUpdatedExpiryDate(false)
			setShowBanner(false)
			// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/action-needed', label: 'Action Needed'}])
			setFilterApplied(true)
			// trackMoengageEvent('action_needed_section_visited');
		}else if(index === '3'){
            setCurrentab('priority3')
            trackEvent('zomatoHotLeadsP3TabClicked', {},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            setFilterApplied(true)
        }
	}

	const getUpdatedExpiryApplications = () => {
		trackEvent('view_expiry_list_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowBanner(false)
		setTrackUpdatedExpiryDate(true)
		setFilterApplied(true)
	}

	const getLatestRapidoDocumentStatuses = (item) => {
		const paramObject = {
			mitraId: mitra?.id,
			isSingleCandidate: false,
			company: item,
		};

		getRapidoDocumentStatuses(paramObject).then((res) => {
			const data = res.data;
			if (data) {
				if (data.success) {
					notification['success']({
						message: data.message ? data.message : 'Latest Document Status Refreshed Successfully.'
					});
				}
				else {
					notification['error']({
						message: data.message ? data.message : 'Error while Refreshing Latest Document Status.'
					});
				}
			}
			else
				notification['error']({
					message: 'Error while Refreshing Latest Document Status.'
				});
		}).catch((err) => {
			notification['error']({
				message: 'Error while Refreshing Latest Document Status.'
			});
		});
	};

	const onRefreshDocumentStatusButtonClick = (e) => {
		const { key } = e;

		switch (key) {
			case 'rapido':
				trackEvent(`refresh_doc_status_rapido`,{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			case 'rapido_3pl':
				trackEvent(`refresh_doc_status_rapido_3pl`,{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			default:
				break;
		}
	};

	const refreshDocumentStatusMenu = (
		<Menu onClick={(e) => onRefreshDocumentStatusButtonClick(e)}>
			{CONSTANTS.REFRESH_DOCUMENT_STATUS_MENU.map((item, index) => {
				return <Menu.Item key={item.key}>
					{getDisplayableClientName(item.label)}
				</Menu.Item>
			})}
		</Menu>
	);


	return (
		<>
			<div className="LeadPage mainContentZ">
				<div className="header">
					{/* <PageHeaderComponent breadcrumb={breadcrumb} /> */}
                    <div style={{ backgroundColor: 'white', padding: '5px 10px' }}>
                        <span style={{ color: '#D34612', fontSize: '1.6em', fontWeight: '1000' }}><bold>Zomato Hot Leads</bold></span>
                    </div>
					<div style={{marginLeft: 'auto', display: 'flex', flexDirection: 'row'}}>
						{/* <SearchComponent placeholder={"Search Candidates" } searchList={searchCandidate}/> */}
						<Button onClick={()=>{
								history.push("/search-page")
							}} style={{color:'#D34612',display:'flex',alignItems:'center'}} icon={<SearchOutlined />}  danger ghost size={'large'} >Search</Button>
					</div>
				</div>
                <div style={{ backgroundColor: '#f7f6ff', padding: '15px', borderRadius: '5px' , display: 'inline-block', textAlign: 'left', marginBottom: '20px', marginLeft: '0px', width: '100%'}}>
                    <ProfileOutlined style={{ marginRight: '10px', fontSize: '16px', color: '#605ae5' }} />
                    <Text strong style={{ marginTop: '5px', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>This is a </Text>
                    <Text strong style={{ marginTop: '5px', color: '#605ae5', fontSize: '16px', fontWeight: 'bold' }}> priority list of your referrals, </Text>
                    <Text strong style={{ marginTop: '5px', color: 'black', fontSize: '16px' }}> who haven&apos;t been onboarded yet created by Vahan Intelligence Engines, </Text> <br></br>
                    <Text strong style={{ marginTop: '5px', color: 'black', fontSize: '16px' }}>   We suggest that your team </Text>
                    <Text strong style={{ marginTop: '5px', color: '#605ae5', fontSize: '16px', fontWeight: 'bold' }}>call candidates in priority list 1, followed by 2 & 3 </Text>
                    <Text strong style={{ marginTop: '5px', color: 'black', fontSize: '16px' }}> for maximising your productivity and conversion rates. </Text>
                </div>
				{/* {
					!mitraReducer?.mitraInfo?.managerMitraID && <div>
						<img onClick={()=>{
							history.push('/refer-and-earn');
							trackMoengageEvent('mitra_lead_program_banner_clicked')
						}} style={{cursor:"pointer"}} className='img-fluid' src='https://uploads-a.vahan.co/VZq34b-image-(39).png' />
					</div>

				} */}
				<div className="more-filters">
					<div className="filter-set-container">
						<Button style={(applyFilterCondition()) ? {color:'#D34612',backgroundColor:'#FFEAE0',border:'1px solid #D34612'} :null} onClick={()=>setfilterSider(true)} icon={<FilterOutlined />}>Apply Filters</Button>
						<div className="desktop-pagination" style={{}}>
							{
								totalApplicationCount > 0 ? 
									<Pagination
									total={totalApplicationCount > 0 && totalApplicationCount}
									showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
									defaultPageSize={pageLimit}
									defaultCurrent={currentPage}
									responsive={true}
									showLessItems={true}
									onChange={(page) => {setCurrentPage(page); setFilterApplied(true)}}
									// hideOnSinglePage={true}
									showSizeChanger={false}
									/>
								: null
							}
						</div>
					</div>
					<div className="Chips-container">
						{
							jobseekerApplicationInML(mitraReducer?.mitraInfo) && applicationType?.map((single_status,index) => {
								if (single_status.value) {
									return <Tag key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'applicationType',single_status)}>{single_status.label} </Tag>
								}
							})
						}
							{
								(startDate !== null && endDate !== null) ?<>
									<Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
									<Text style={{marginRight: '3px'}}>to</Text>
									<Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag>
								</>
									: null
							}
							{
								trackUpdatedExpiryDate ? 
									<Tag className="single-chip" closable onClose={(e)=>preventDefault(e,'updateExpiryFilter', false)}> Expiry Date Changed</Tag>
								: null
							}		
							{
								clientSelected?.map((single_category,index) => {
									if (single_category.value) {
										return <Tag key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'category',single_category)}>{getDisplayableClientName(single_category.label)} </Tag>
									}
								})
							}

							{
								statusesSelected?.map((single_status,index) => {
									if (single_status.value) {
										return <Tag  key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'status',single_status)}>{single_status.label} </Tag>
									}
								})
							}
							{
								[...new Set(locationList)]?.map((single_status, index) => {
									if (single_status.value) {
										return <Tag key={index} className="single-chip" closable onClose={(e)=>preventDefault(e,'location',single_status)}>{single_status.label} </Tag>
									}
								})
							}
							{
								mitraAppDownloadedFilter?.map((single_status,index) => {
									if (single_status.value) {
										return <Tag key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'mitraAppDownload',single_status)}>{single_status.label} </Tag>
									}
								})
							}
							{
								documentStatusFilter?.map((single_status,index) => {
									if (single_status.value) {
										return <Tag key={'tagLead'+index} className="single-chip" closable onClose={(e)=>preventDefault(e,'documentStatus',single_status)}>{single_status.label} </Tag>
									}
								})
							}
					</div>
				</div>

				{
					showBanner ?
					<div style={{margin: '20px'}}>
						<div className="warning-message-at-risk-container">
							<div className="warning-message-atrisk" >
								<InfoCircleOutlined  style={{color: '#D34612', margin: '13px 10px'}} />
								<div className='flex-desktop-row-mobile-column'>
									<Text style={{ margin: '10px 0px'}}>Expiry dates for some of your candidates have changed due to updated time-gating conditions.</Text>
									<Text className='view-list-updated-expiry' onClick= {() => getUpdatedExpiryApplications()}> 
										<Button type="primary" style={{ margin: '5px 0px'}}>View list</Button>
									</Text>
								</div>
								
								{/* <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} /> */}
							</div>
						</div>
					</div>
					: null
				}
				
			<Modal style={{padding:'40px'}}  key="informationModal" visible={isModalOpen} open={isModalOpen} onCancel={()=>{setIsModalOpen(false);location.state=null;}} footer={[]}>
				<Result
					status="success"
					extra={[
						<h6 key="leadStatus" style={{color:'green'}}> Your lead has been referred.</h6>,
						<h3 style={{textAlign:'left'}} key="infoModalTextHeader">Complete the	 steps below to get your lead activated.</h3>,

						...candidatePostReferralSteps,
						<br key="break" />,
						<Button style={{  background: '#53c31b',
							color: '#ffff',
							borderRadius: '5px'}}  onClick={()=>{whatsappModalOpen();}} key="whtsappMessageButton">Message On <WhatsAppOutlined style={{fontSize:'20px'}} /></Button>,


					]}
				/>
			</Modal>
			{
				whatsAppMessageModal ?	<WhatsappTemplateModal
				lead={selectedLead}
				leadDetails={leadDetails}
				whatsAppMessageModal={whatsAppMessageModal}
				whatsAppMessageModalCancel={whatsAppMessageModalCancel}

			></WhatsappTemplateModal>:null
			}
			
				<Tabs defaultActiveKey={tab === 'action-needed' ? '2' : '1'} centered className='candidates-tab-container' onChange={onChangeTabs}>
                    <Tabs.TabPane tab={<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Highest Priority</span>} key="1">
					</Tabs.TabPane>
					<Tabs.TabPane tab={<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Medium Priority</span>} key="2">
					</Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Low Priority</span>} key="3">
					</Tabs.TabPane>
					{/* <Tabs.TabPane tab="Action Needed" key="2">
					</Tabs.TabPane> */}
				</Tabs>
				{/* <div className="tabs-container"> */}
				
					{/* <button id="allCandidates"
						className= {!atRiskTab ? "tab active-tab" : "tab"}
						onClick={(e) => {
							setAtRiskTab(false);
							setbreadcrumb([{link: '/leads', label: 'Candidates'},{link: '/leads', label: 'All Candidates'}])
							GAEventsTracker("OpenAllCandidatesTab")
							setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD')); setEndDate(moment());
							setFilterApplied(true)
						}}>
						All Candidates
					</button>
					<button id="atRiskApplication"
						className= {atRiskTab ? "tab active-tab" : "tab"}
						onClick={(e) => {
							setAtRiskTab(true);
							setbreadcrumb([{link: '/leads', label: 'Candidates'},{link: '/leads', label: 'Critical Candidates'}])
							GAEventsTracker("OpenAtRiskTab")
							setFilterApplied(true)
							setStartDate(moment().subtract(13, 'd')); setEndDate(moment().subtract(7, 'd'));
						}}>
							<InfoCircleOutlined  style={{color: '#D34612', verticalAlign: 'baseline'}} />
						<span> Critical Candidates</span>
					</button> */}
				{/* </div> */}
                {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
    {/* Information div */}
    <Row justify="start" gutter={[16, 16]} style={{marginLeft: '10px'}}>
    <Col xs={24} sm={24} md={16} lg={18}>
        { currentTab == 'priority1' ? (<div style={{ backgroundColor: '#e8cabe', padding: '15px', borderRadius: '5px' , display: 'inline-block'}}>
            <InfoCircleOutlined style={{ marginRight: '8px', fontSize: '16px', color: '#d34612' }} />
            <Text strong style={{ marginTop: '5px', color: '#d34612', fontSize: '16px', fontWeight: 'bold' }}>5x chances of conversion! </Text>
            <svg width="8" height="8" viewBox="0 0 10 10" fill="#d34612" style={{ verticalAlign: 'middle' }}>
                <circle cx="4" cy="4" r="4" />
            </svg>
            <Text strong style = {{color: '#d34612'}}> Highest priority leads </Text> <br></br>
            <Text strong> Highest priority leads are those 1. Who have downloaded the Zomato app, or 2. Completed some sections of the zomato app; or 3. May also have paid their onboarding fee, but have not been onboarded </Text>
            
        </div>): (currentTab == 'priority2' ? (<div style={{ backgroundColor: '#e8cabe', padding: '15px', borderRadius: '5px', display: 'inline-block' }}>
            <InfoCircleOutlined style={{ marginRight: '8px', fontSize: '16px', color: '#d34612' }} />
            <Text strong style={{ marginTop: '5px', color: '#d34612', fontSize: '16px', fontWeight: 'bold'}}>Medium chances of conversion!</Text><br></br>
            <Text strong>Medium Priority Leads who had downloaded the Zomato app before you referred them.</Text><br></br>
            
        </div>) : (<div style={{ backgroundColor: '#e8cabe', padding: '15px', borderRadius: '5px', display: 'inline-block'}}>
            <InfoCircleOutlined style={{ marginRight: '8px', fontSize: '16px', color: '#d34612' }} />
            <Text strong style={{ marginTop: '5px', color: '#d34612', fontSize: '16px', fontWeight: 'bold' }}>Low chances of conversion!</Text><br></br>
            <Text strong>Zomato App not Downloaded. Try to get these leads to download the Zomato app to increase their conversion chances!</Text>
        </div>))}
    </Col>

    {/* Conditional button based on isAdmin */}
    {isAdmin &&
        <Col xs={24} sm={24} md={8} lg={6}>
            <div style={{ textAlign: 'right', marginRight: '20px' }}>
                <Button style={{ marginTop: '10px' }} danger loading={downloadLoading} onClick={() => { handleDownload(); AllCandidatesGAEventsTracker("DownloadAllCandidatesData"); }}>
                    Download File
                </Button>
            </div>
        </Col>
    }
</Row>
    {/* </div> */}
				

				<div className="leads-wrapper">

					{loaded ? leads.length === 0 ? (
						<div style={{margin: '20px auto', height: '68vh'}}>
							<Empty 
							description={
								<span>
									<button onClick={()=>history.push('/add-single-candidate')} style={{textDecoration:'underline', color:'red', fontWeight:'600'}}>Refer Candidates</button> to see data here.
								</span>
							} />
						</div>
					) : (
						leads.map((lead, index) => (
							<Lead
								key={index}
								lead={lead}
								leadDetails={leadDetails}
								setShowDetail={setShowDetail}
								setSelectedLead={setSelectedLead}
								setReferAgain={setReferAgain}
								setCurrentJob = {setCurrentJob}
								setSpin={setSpin}
								mitra = {mitra}
								setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
								setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
							/>
						))
					) : (
						<Skeleton active avatar />
					)}
					<br />
					<div className="mobile-pagination" style={{}}>
							{
								totalApplicationCount > 0 ? 
								<Pagination
								total={totalApplicationCount > 0 && totalApplicationCount}
								showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
								defaultPageSize={pageLimit}
								defaultCurrent={currentPage}
								responsive={true}
								showLessItems={true}
								onChange={(page) => {setCurrentPage(page); setFilterApplied(true)}}
								showSizeChanger={false}
								/>
								: null
							}
						</div>
				<br/>
				<a id="back-to-top" className="cta hideCta" onClick={() => scrollToTop()}>↑</a>
				<br/>
				</div>
				<div className="bottom-detail-wrapper">
					{
					showDetail && (
					<CandidateProfilePopupV2 
					selectedScheduledInterviewId={selectedScheduledInterviewId} 
					selectedRecommendationHistoryId={selectedRecommendationHistoryId}
					visible={showDetail} 
					setShowDetail={setShowDetail} 
					lead={selectedLead}
					setRaiseIssueSider={setRaiseIssueSider}
					setViewDetailLead={setViewDetailLead}
					>
						
					</CandidateProfilePopupV2>)
					}
				</div>
			</div>
			{referAgain &&
				<SideSheet submit={{disabled:submitdisable,onClick:submitLeadFilter}} sidername='Add New Number' onDismiss={() => setReferAgain(false)}>
					<div className="siderInnerContainer1">
						<div className="outerContainer">
						<SideInput onChange={onPhoneNumberChange} placeholder={'Example : 8884228005'}
							imgsrc={'phone.svg'} labelname={"* Add Alternate Number"}/>
						<SideInput readOnly={true} value={selectedLead.phoneNumber}
							imgsrc={'phone.svg'} labelname={"Contact Number"}/>
						</div>
					</div>
				</SideSheet>
			}
			{filterSider && 
				<SideSheet submit={{disabled:false
					,onClick:submitFilter
				}}
				clearAll={{disabled:false,onClick:clearAll}}
				sidername={"Filter Candidates"} 
				onDismiss={() => {setfilterSider(false)}}>
					<div className='filter-home-wrapper'>
						{/* {jobseekerApplicationInML(mitraReducer?.mitraInfo) && commonTag("Application Type", applicationType)} */}
						{commonTag("Date Range",null)}
						{commonTag("Status",statusesSelected)}
						{/* {commonTag("Clients",clientSelected)} */}
						{/* {commonTag("Location", locationList)} */}
						{/* {commonTag(rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo) ? "Mitra App" : "Mitra App (Mitra Clients)", mitraAppDownloadedFilter)} */}
						{/* {commonTag("Document Status (Mitra Clients)", documentStatusFilter)} */}
					</div>
				</SideSheet>
			}	
			{raiseIssueSider &&
                <RaiseIssueSider
                    closeSider={() => setRaiseIssueSider(false)}
                    mitraReducer={mitraReducer}
					selectedLead={viewDetailLead}
                />
            }
			{
                showVerifyEmailPopup && 
                (
                    <VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
                )
            }	
		</>

	);
};

export default ZomatoHotLeadsPage;
