import React, {useContext, useEffect, useRef, useState} from "react"
import {Formio} from "react-formio"
import {submitBasicInfoForm, getJobDetails, checkUberUniqueness, checkRapidoUniqueness, checkZeptoUniqueness, updateZeptoAPIUniqueness} from "../service/homeService"
import { postCandidate } from "../service/additionalInfoService"
import JobRecommendationaPage from "./JobRecommendationPage"
import '../css/basicInfo.css'
import {useHistory} from 'react-router-dom';
import {
    createJobRecommendationAction,
    getRecommendedJobs,
    submitJobs
  } from '../service/jobRecommendation';
import { trackEvent } from '../service/engagementMonitoringService';
import CONSTANTS from '../constants/constants';
// import { hideJobCategoryField } from "../constants/utils"
import * as queryString from 'query-string';
import { AppContext } from "../App"
import { PageHeader, notification } from "antd"


const BasicInfoForm = ({name, phoneNumber, alternatePhoneNumber}) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const {mitraReducer, mitraDispatch,setAlert} = useContext(AppContext);
    const mitra = mitraReducer?.mitraInfo;
    const [formData, setFormData] = useState({})
    const formRef=useRef(null);
    const history = useHistory();
    const clientJobCategoryMapping = CONSTANTS.CLIENT_AND_JOB_CATEGORY_MAPPING;
    const parsedUrl = queryString.parse(window.location.search);
	let basicInfoFormPrefilledObject = {
			cityPreference: parsedUrl?.city,
			desiredRole: parsedUrl?.category,
			clientPreference: parsedUrl?.client,
			firstLocalityPreference: parsedUrl?.locality || null,
	}

    const options = {
        hooks: {
            beforeSubmit: async (submission, next) => {
                let errors = [];

                // if Job Category field is not filled
                if (submission.data.desiredRole !=='Delivery' || submission.data.desiredRole !=='Driver') {
                    submission.data.desiredRole = clientJobCategoryMapping[submission?.data?.clientPreference?.toLowerCase()];
                }

                try {
                    const res = await submitBasicInfoForm(submission.data, mitra)
                    for (let key in res.data) {
                        if (res.data[key] !== "200" && key != 'clientPreference')
                            errors.push({message: res.data[key]})
                    }
                } catch (error) {
                    if (error.response)
                        errors = error.response.data.message ? [{message: error.response.data.message}] : [{message: "something went wrong"}]
                }

                if (errors.length > 0) {
                    errors.map((error, index) => (
                        error.message = error.message.replace('500: Error: Error:', '').trim()
                    ))
                    next(errors)
                }
                else {
                    const obj = {
                        company: submission.data.clientPreference,
                        jobCategory: submission.data.desiredRole,
                        jobLocation: submission.data.cityPreference,
                        jobLocationLocality: submission.data.firstLocalityPreference
                    }
                    const jobDetails = await getJobDetails(obj)

                    if(jobDetails?.data) {
                        submit(jobDetails.data, submission.data, mitra)
                    }
                    else{
                        errors.push({message: "No job found"})
                    }
                }

            }
        }
    }

    useEffect(() => {
        const plugin = {
            priority: 0,
            requestOptions: function (value) {
                value.credentials = 'include';
                return value;
            }
        };

        document.addEventListener('showAlternatePhoneNumber', (e) => {
            console.log(e);
        })
        let formURL=process.env.REACT_APP_FORM_IO_BASIC_INFO_FORM_URL_SELECTED_MITRAS;
        // if (mitraReducer && mitraReducer.mitraInfo && hideJobCategoryField(mitraReducer?.mitraInfo)) {
        //     if (process.env.NODE_ENV !== 'development') {
        //         formURL = process.env.REACT_APP_FORM_IO_BASIC_INFO_FORM_URL_SELECTED_MITRAS
        //     }
        // }
        const loadForm = async () => {
            await Formio.createForm(document.getElementById("form-io"), formURL, options).then(form => {
                form.submission = {
                    data: {
                        phoneNumber,
                        name,
                        alternatePhoneNumber,
                        cityPreference: basicInfoFormPrefilledObject.cityPreference ? basicInfoFormPrefilledObject.cityPreference : '',
                        clientPreference: basicInfoFormPrefilledObject.clientPreference ?basicInfoFormPrefilledObject.clientPreference : '' ,
                        // desiredRole: basicInfoFormPrefilledObject.desiredRole ? basicInfoFormPrefilledObject.desiredRole : '',
                        firstLocalityPreference: basicInfoFormPrefilledObject.firstLocalityPreference ? basicInfoFormPrefilledObject.firstLocalityPreference : ''
                    }
                }

                // Experimenting with removing Job Category field for some Mitra Leaders
                // if (mitraReducer && mitraReducer.mitraInfo && hideJobCategoryField(mitraReducer?.mitraInfo)) {
                const jobCategoryField = document.getElementsByClassName('formio-component-desiredRole');
                if (jobCategoryField.length)
                    jobCategoryField[0].style.display= 'none';
                // }
            })

            if (Formio.plugins.length === 0)
                Formio.registerPlugin(plugin, "enableCredentials")
        }
        if (formURL) {
            loadForm()
        }
        setTimeout(() => {
            const allAlternative = document.getElementsByClassName('showAlternatePhoneNumber');
            if (allAlternative.length) {
                allAlternative[0].addEventListener('click', () => {
                    console.log('cicked');
                    allAlternative[0].style.display = "none";
                    document.getElementsByClassName('alternatePhoneNumber')[0].style.display = "block";


                })
            }
            const showLocationPreference2 = document.getElementsByClassName('showLocationPreference2');
            if (showLocationPreference2.length) {
                showLocationPreference2[0].addEventListener('click', () => {
                    showLocationPreference2[0].style.display = "none";
                    document.getElementsByClassName('locationPreference2')[0].style.display = "block";
                })
            }

            const showLocationPreference3 = document.getElementsByClassName('showlocationPreference3');
            if (showLocationPreference3.length) {
                showLocationPreference3[0].addEventListener('click', () => {
                    showLocationPreference3[0].style.display = "none";
                    document.getElementsByClassName('locationPreference3')[0].style.display = "block";
                })
            }
            /** For Future */

            let allInputForm = formRef.current && formRef.current.getElementsByClassName('form-group has-feedback formio-component');
            if(!mitraReducer?.timeGauge && allInputForm){
            //Onclick of input 
            for (var i=0; i < allInputForm.length; i++) {
                allInputForm[i].onclick = function(e){
                        mitraDispatch({type:'timeGauge',value:{label:'addcandidate' , startTime:Date.now(), endTime:null}}) // {name, start,end}-> reducer-> performance gauge 
                    }
                }
            }
            
        }, 2000);
        return () => {Formio.plugins = []}

    }, [options])

    const submit = async (jobDetails, basicData, mitra) => {
        let errors = [];
        try {
            const jobObject = {
                ...jobDetails,
                companyName: basicData.clientPreference,
            }
          const createJobRecommendationActionRes = await createJobRecommendationAction(
            jobObject,
            basicData,
            mitra
          );
          if (createJobRecommendationActionRes.data.createJobRecommendation !== '200') {
            setAlert({
              display: true,
              text: createJobRecommendationActionRes.data.createJobRecommendation,
              type: 'error'
            });
            // setDisableSubmit(false);
            return;
          }
          if(basicData.clientPreference.toLowerCase().indexOf('uber') !== -1){
            const uberUniquenessResponse = await checkUberUniqueness(basicData.phoneNumber, basicData.cityPreference, basicData.clientPreference);
            console.log('uberPhoneNumberUniquenessResponse:>>', uberUniquenessResponse)
            if(uberUniquenessResponse && uberUniquenessResponse.data && !uberUniquenessResponse.data.isUnique){ // if not unique
                const leadObj = {
                    ...basicData, 
                    companyName: basicData.clientPreference, 
                    jobId: jobDetails.jobId, 
                    jobDemandId: jobDetails.jobDemandId, 
                    companyCity: basicData.cityPreference
                }
                await postCandidate(basicData, mitra, leadObj, true); // mimic si creation
                notification['error']({
                    message: 'This phone number is already registered with the client!',
                  });
                return history.replace({pathname:"/leads/all-candidates"});
            }
            else if(uberUniquenessResponse && uberUniquenessResponse.data && uberUniquenessResponse.data.isUnique){
                // notification['success']({
                //     message: 'This phone number is Unique!',
                //   });
            }
            else{
                setAlert({
                    display: true,
                    text: "Something went wrong while checking uniqueness on Phone Number!",
                    type: 'error'
                  });
                  return;
            }
          }


          if(basicData.clientPreference.toLowerCase().indexOf('zepto') !== -1){
            console.log('Inside Zepto Check')
            let zeptoUniquenessResponse 
            try {
                zeptoUniquenessResponse = await checkZeptoUniqueness([basicData.phoneNumber]);
            } catch (err) {
                console.error(err.message)
            }
            
            // console.log(zeptoUniquenessResponse, 'zeptoUniquenessResponse')
            // console.log(zeptoUniquenessResponse.data, 'zeptoUniquenessResponse data')
            // console.log(zeptoUniquenessResponse.data.data, 'zeptoUniquenessResponse data.data')

            zeptoUniquenessResponse = zeptoUniquenessResponse.data;
            console.log('zeptoUniquenessResponse:>>', zeptoUniquenessResponse.response)
            // debugger
            if(zeptoUniquenessResponse && zeptoUniquenessResponse.response && zeptoUniquenessResponse.response[basicData.phoneNumber] &&  zeptoUniquenessResponse.response[basicData.phoneNumber].data 
                && zeptoUniquenessResponse.response[basicData.phoneNumber].data.status && zeptoUniquenessResponse.response[basicData.phoneNumber].data.status !== 'eligible'){ // if not unique
                const leadObj = {
                    ...basicData, 
                    companyName: basicData.clientPreference, 
                    jobId: jobDetails.jobId, 
                    jobDemandId: jobDetails.jobDemandId, 
                    companyCity: basicData.cityPreference
                }
                await postCandidate(basicData, mitra, leadObj, true); // mimic si creation
                try {
                    await updateZeptoAPIUniqueness(jobDetails.jobDemandId, basicData.phoneNumber, 'not_eligible')
                } catch(err) {
                    console.error(err);
                }
                notification['error']({
                    message: 'This phone number is already registered with the client!',
                  });
                return history.replace({pathname:"/leads/all-candidates"});
            } else if(zeptoUniquenessResponse && zeptoUniquenessResponse.response && zeptoUniquenessResponse.response[basicData.phoneNumber] &&  zeptoUniquenessResponse.response[basicData.phoneNumber].data 
                && zeptoUniquenessResponse.response[basicData.phoneNumber].data.status && zeptoUniquenessResponse.response[basicData.phoneNumber].data.status === 'eligible') {
                    //  Go to 2nd Page
                    try {
                        await updateZeptoAPIUniqueness(jobDetails.jobDemandId, basicData.phoneNumber, 'eligible')
                    } catch(err) {
                        console.error(err);
                    }
                    notification['success']({
                        message: 'Candidate Can be onboarded',
                      });                    
                    // return
            } else{
                setAlert({
                    display: true,
                    text: "Something went wrong while checking uniqueness on Phone Number!",
                    type: 'error'
                  });
                  return;
            }
        }

          if(basicData.clientPreference.toLowerCase().indexOf('rapido_3pl') !== -1){
            const rapidoUniquenessResponse = await checkRapidoUniqueness(basicData.phoneNumber);
            console.log('rapidoPhoneNumberUniquenessResponse:>>', rapidoUniquenessResponse)
            if(rapidoUniquenessResponse && rapidoUniquenessResponse.data && !rapidoUniquenessResponse.data.isUnique){ // if not unique
                const leadObj = {
                    ...basicData, 
                    companyName: basicData.clientPreference, 
                    jobId: jobDetails.jobId, 
                    jobDemandId: jobDetails.jobDemandId, 
                    companyCity: basicData.cityPreference
                }
                await postCandidate(basicData, mitra, leadObj, true); // mimic si creation
                notification['error']({
                    message: 'This phone number is already registered with the client!',
                  });
                return history.replace({pathname:"/leads/all-candidates"});
            }
            else if(rapidoUniquenessResponse && rapidoUniquenessResponse.data && rapidoUniquenessResponse.data.isUnique){
                // notification['success']({
                //     message: 'This phone number is Unique!',
                //   });
            }
            else{
                setAlert({
                    display: true,
                    text: "Something went wrong while checking uniqueness on Phone Number!",
                    type: 'error'
                  });
                  return;
            }
          }
    
          const res = await submitJobs(jobObject, basicData, mitra);
          if (res.checkAdditionalForm) {
            const leadObj = {
                ...basicData, 
                companyName: basicData.clientPreference, 
                jobId: jobDetails.jobId, 
                jobDemandId: jobDetails.jobDemandId, 
                companyCity: basicData.cityPreference
            }

            if(leadObj?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
                // If the client is zomato and has an additional form then
                return history.push({pathname: '/zomato-additional-form',
                route: 'jobRecommendationPage',
                search:new URLSearchParams(leadObj).toString()});
            } else if(leadObj?.companyName?.toLowerCase().indexOf('blinkit') !== -1) {
                // If the client is zomato and has an additional form then
                return history.push({pathname: '/blinkit-additional-form',
                route: 'jobRecommendationPage',
                search:new URLSearchParams(leadObj).toString()});
            } else if(leadObj?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
                // If the client is swiggy and has an additional form then
                // return history.push({pathname: '/swiggy-additional-form',
                // route: 'jobRecommendationPage',
                // search:new URLSearchParams(leadObj).toString()});
                return history.replace({pathname:"/leads/all-candidates"});
            } else if (leadObj?.companyName?.toLowerCase() === 'zepto') {
                // If the client is zepto and has an additional form then
                return history.push({pathname: '/zepto-additional-form',
                route: 'jobRecommendationPage',
                search:new URLSearchParams(leadObj).toString()});
            } else if (leadObj?.companyName?.toLowerCase() === 'swiggy soc') {
                return history.push({pathname: '/swiggy-soc-additional-form',
                route: 'jobRecommendationPage',
                search:new URLSearchParams(leadObj).toString()});
            } else if(leadObj?.companyName?.toLowerCase() === 'shadowfax'){
                return history.replace({pathname:"/leads/all-candidates"});
            }else {
                // For clients other than zomato
                return history.push({pathname: '/moreInfo',
                route: 'jobRecommendationPage',
                search:new URLSearchParams(leadObj).toString()});
            }
            
          }
          for (let key in res.data) {
            if (res.data[key] !== "200")
              errors.push(`${key}: ${res.data[key]}`)
          }
        } catch (error) {
          errors = error.response ? [error.response.data.message] : ["something went wrong"]
        }
    
        if (errors.length > 0) {
          setAlert({
            display: true,
            text: errors[0],
            type: 'error'
          });
        }
        else {
          trackEvent('single_application_created', {
            client_name: basicData.clientPreference,
            application_city_name: basicData.cityPreference,
            source_id: mitra.id,
            basic_profile_to_job_selection_tat: Date.now() - mitraReducer.timeGauge?.startTime // in ms
          }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
          history.replace({pathname:"/leads/all-candidates",state:JSON.stringify({clientPreference:basicData.clientPreference,phoneNumber:basicData.phoneNumber})});
        }
      };

    let content;
    
    if (!formSubmitted) {
        content = (
            <>
                <div
                className="header"
                style={{ marginBottom: 4, display: 'flex', justifyContent: 'space-between' }}
                >
                    <span className="page-header-font">
                        Add Candidate
                    </span>
                </div>
                <div className="parentFormClass">
                    <div ref={formRef} id="form-io"></div>
                </div>
            </>
           
        )
    }
    // else {
    //     content = (
    //         <JobRecommendationaPage mitra={mitra} basicFormData={formData} mitraReducer={mitraReducer} setAlert={setAlert} />
    //     )
    // }

    return content
}

export default BasicInfoForm
