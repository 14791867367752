import React, { useState } from 'react'
import {CopyOutlined, UserOutlined,ArrowLeftOutlined, PlusOutlined, AppstoreAddOutlined, PhoneOutlined, CloudDownloadOutlined,SlidersOutlined} from '@ant-design/icons'
import { Avatar, Button } from 'antd';
import { returnSamvaadiniFilteredLeadsBgColor } from '../../../constants/utils';
import CopyIcon from '../../../static/svg/CopyIcon';
import CallIcon from '../../../static/svg/CallIcon';
import moment from 'moment';
import CopyTextCheckMark from '../../../static/svg/CopyTextCheckMark';

function ProcessedCandidateCard({indexVal, candidate}) {
    const [copyOperationSuccessful, setCopyOperationSuccessful] = useState(false);
    const noReferralTxt = "no_referral"

    const copyPhoneNumber = async() => {
        try {
            await navigator.clipboard.writeText(candidate?.phoneNumber);
            setCopyOperationSuccessful(true);
            
            setTimeout(() => setCopyOperationSuccessful(false), 1000);
          } catch (err) {
            console.log(err);
          }
    }
  return (
    <div className="filtered-individual-candidate hoverable-card" style={{background: returnSamvaadiniFilteredLeadsBgColor(indexVal)}}>
        <div className="client-info-section">
            {
                candidate?.type == noReferralTxt ? <img src="/image/no_referred_diamond.png" className='non-referred-img'/> : <img src={candidate?.latestClientLogoUrl} style={{width:'56px'}}/>
            }
        </div>

        <div className="filtered-lead-info-container">
            <div className="filtered-lead-info d-flex align-items-center">
                <img src='/image/candidate-samvaadini.png' style={{height: '36px',width:'36px'}}/>
                <div className="filtered-candidate d-flex flex-column">
                    <span className='filtered-lead-name'>{candidate?.name}</span>
                    <span className='filtered-lead-number'>{candidate?.phoneNumber}</span>
                </div>
            </div>
            <div className="action-ctas">
                <div style={{cursor: 'pointer', marginTop: copyOperationSuccessful ? '-2px':''}} onClick={copyPhoneNumber}>
                    {
                        copyOperationSuccessful ? <CopyTextCheckMark height={20} width={20} style={{marginTop:'-6px'}}/>:<CopyIcon height={24} width={24}/>
                    }
                    
                </div>

                <a href={`tel:+91-${candidate?.phoneNumber}`}>
                    <div style={{cursor: 'pointer'}}><CallIcon height={24} width={24}/></div>
                </a>
            </div>
        </div>

        <div className="filtered-lead-other-details d-flex align-items-center">
            <div className="field-container align-items-start">
                <span className='filtered-lead-header'>{candidate?.type == noReferralTxt ? "Upload Date" : "Referral Date"}</span>
                <span className='filtered-lead-sub-header'>{candidate?.type == noReferralTxt ? moment(candidate?.uploadDate).format('DD/MM/YYYY') : candidate?.latestClientReferredDate ? moment(candidate?.latestClientReferredDate).format('DD/MM/YYYY'): null}</span>
            </div>

            {
                candidate?.type == noReferralTxt ? null :
                <div className="field-container align-items-start">
                    <span className='filtered-lead-header'>City</span>
                    <span className='filtered-lead-sub-header'>{candidate?.latestReferredLocation}</span>
                </div>
            }
            <div className="field-container align-items-start">
                <span className='filtered-lead-header'>Owner</span>
                <span className='filtered-lead-sub-header'>{candidate?.referredBy}</span>
            </div>

            {
                candidate?.status ? (
                    <div className="field-container align-items-start">
                        <span className='filtered-lead-header'>Status</span>
                                <span 
                                className={`filtered-lead-sub-header chip-status ${
                                    candidate?.status?.toLowerCase() === 'interested' ? 'chip-status-interested':
                                    candidate?.status?.toLowerCase() === 'not interested' ? 'chip-status-not-interested':
                                    candidate?.status?.toLowerCase() === 'rnr' ? 'chip-status-rnr': 'chip-status-not-eligible'
                                }`}>
                                    {candidate?.status || 'RnR'}
                                </span>
                    </div>):null
            }
               
        </div>
    </div>
  )
}

export default ProcessedCandidateCard